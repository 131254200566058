<template>
<div class="backdrop" @click="backdropClick">
	<div class="modal" :class="{ wide: props.wide, 'wide-ish': props.wideIsh, 'no-padding': props.noPadding }">
		<font-awesome-icon v-if="!props.hideCloseIcon" icon="fa-regular fa-xmark" class="close" @click="close" />
		<div class="header" v-if="slots.header">
			<slot name="header" />
		</div>
		<div class="body">
			<slot />
		</div>
		<div v-if="$slots.footer" class="footer">
			<slot name="footer" />
		</div>
	</div>
</div>
</template>

<script lang="ts" setup>
const slots = useSlots()
const emit = defineEmits([ 'close' ])
const props = defineProps<{
	confirmOnClose?: string,
	hideCloseIcon?: Boolean,
	wide?: Boolean,
	wideIsh?: Boolean,
	noPadding?: Boolean,
}>()

function close()
{
	if (props.confirmOnClose && !confirm(props.confirmOnClose))
		return
	
	emit('close')
}

function keyboardEvent(event: KeyboardEvent)
{
	if (!event)
		return

	if (event.key === 'Escape')
	{
		close(event)
	}
}

function backdropClick(event: MouseEvent)
{
	if (event.target === event.currentTarget)
	{
		close()
	}
}

onMounted(() => {
	window.addEventListener('keyup', keyboardEvent)
	document.body.style.overflow = 'hidden'
	setTimeout(() => {
		document.querySelector('.backdrop')?.scrollTo(0, 0)
	}, 10)
})

onUnmounted(() => {
	document.body.style.overflow = 'auto'
	window.removeEventListener('keyup', keyboardEvent)
})
</script>

<style lang="scss">
.backdrop {
	z-index: 100;
	position: fixed;
	overflow-x: hidden;
	overflow-y: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 5% 0;
	background: rgba(0, 0, 0, 0.8);
	backdrop-filter: blur(7px);
	cursor: pointer;
}

.modal {
	color: #000;
	width: auto;
	background: #fff;
	position: relative;
	/* left: 85px; */
	/* right: 85px; */
	cursor: auto;

	margin: 0 auto;
	max-width: 700px;

	box-shadow: 0px 9px 28px 0px rgba(0, 0, 0, 0.5);
	
	&.wide {
		max-width: 1000px;
	}
	
	&.wide-ish {
		max-width: 800px;
	}

	@media (max-width: 720px) {
		margin: 0 10px;
	}
	
	.dark-mode & {
		color: #fff;
		background: #444;
	}
	
	.close {
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;
		
		&:hover {
			color: $primary-blue;
		}
		
		@media (max-width: $breakpoint-phone) {
			top: 5px;
			right: 5px;
		}
	}

	.header {
		padding: 25px 30px;
		font-size: 15px;
		line-height: 1.5;

		@media (max-width: $breakpoint-phone) {
			padding: 20px;
			font-size: 15px;
		}

		h2 {
			font-size: 20px;
			font-weight: 600;
			margin: 0 0 5px 0;
		}
		
		p:last-child {
			margin-bottom: 0 !important;
		}
	}

	.body {
		padding: 40px 30px;
		background: #eaeaea;
		border-top: solid 1px #cfcfcf;

		@media (max-width: $breakpoint-phone) {
			padding: 40px 20px 40px 20px;
		}
		
		.dark-mode & {
			background: #333;
			border-top: solid 1px #555;
		}
		
		h3 {
			margin: 0 0 10px 0;
			font-size: 16px;
			font-weight: 400;
		}
	}
	
	&.no-padding {
		.body {
			padding: 0;
			
			.console-section {
				padding: 30px;
				margin: 0;
				
				@media (max-width: $breakpoint-phone) {
					padding: 20px;
				}
				
				.pricing {
					padding: 0;
				}
			}
			
			.billing-cycles {
				padding: 30px;
				
				@media (max-width: $breakpoint-phone) {
					padding: 20px;
				}
			}
		}
	}
	
	.footer {
		padding: 25px 30px 30px 30px;
		background: #eaeaea;
		border-top: solid 1px #cfcfcf;

		@media (max-width: $breakpoint-phone) {
			padding: 20px;
		}
		
		.dark-mode & {
			background: #333;
			border-top: solid 1px #555;
		}
	}
}
</style>